@import url(https://fonts.googleapis.com/css?family=Audiowide);

body {
  margin: 0;
}
.container {
  background: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.loading {
  display: flex;
  flex-direction: row;
}
.loading__letter {
  font-size: 88px;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: "Audiowide";
  color: #fec468;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading__letter:nth-child(2) {
  animation-delay: .1s; 
}
.loading__letter:nth-child(3) {
  animation-delay: .2s;
}
.loading__letter:nth-child(4) {
  animation-delay: .3s; 
}
.loading__letter:nth-child(5) {
  animation-delay: .4s;
}
.loading__letter:nth-child(6) {
  animation-delay: .5s; 
}
.loading__letter:nth-child(7) {
  animation-delay: .6s;
}
.loading__letter:nth-child(8) {
  animation-delay: .8s;
}
.loading__letter:nth-child(9) {
  animation-delay: 1s;
}
.loading__letter:nth-child(10) {
  animation-delay: 1.2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0px)
  }
  40% {
    transform: translateY(-40px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}


@media (max-width: 700px) {
  .loading__letter {
    font-size: 50px;
  }
}

@media (max-width: 340px) {
  .loading__letter {
    font-size: 40px;
  }
}