.body{
    margin:10px;
}

body{
    
    background: linear-gradient(270deg, #ffffff, #e4e4e4, #dadada);
    background-size: 1800% 1800%;

    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.body-input{
    margin: auto;
    width: 80%;
    border: 3px solid #000;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.body-input-select select{
 height:40px;
 font-size: 18px;
 width:200px;
 margin:10px;
 border-radius:5px;

}

.option-default{
    color:grey;
}
.option-regular{
    color:black;
}
.prevent-click{
    pointer-events: none;
}

.body-input-button{
    text-align: center;
    padding:10px;
}

.body-input-button button{
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid black;
    cursor: pointer;
    display: inline-block;
    background: white;
    width: 100px;
}

.body-input-button button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.body-input-button button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.body-input-button button:hover span {
    padding-right: 15px;
}

.body-input-button button:hover span:after {
    opacity: 1;
    right: 0;
}

.body-output{
    margin: 10px;
}

.body-output-chart-container{
    display: flex;
    flex-wrap: wrap;
}
.chartjs-size-monitor{
    width:fit-content;
}
.body-output-chart{
    width: 425px;
    border: 2px solid black;
    padding: 10px;
    margin: 10px auto;
}
.body-output-chart h2,.body-output-chart h5{
    margin:0px auto;
}
.body-output-chart h5{
    margin-bottom:10px;
}


@media screen and (max-width:600px){
    .body-output-chart{
        width: calc(100% - 20px);
        height: 270px;
    }
    .body-output-chart h2{
        font-size: 18px;
    }
}