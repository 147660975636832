@import url(https://fonts.googleapis.com/css?family=Audiowide);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  margin: 0;
}
.container {
  background: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loading {
  display: flex;
  flex-direction: row;
}
.loading__letter {
  font-size: 88px;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: "Audiowide";
  color: #fec468;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.loading__letter:nth-child(2) {
  -webkit-animation-delay: .1s;
          animation-delay: .1s; 
}
.loading__letter:nth-child(3) {
  -webkit-animation-delay: .2s;
          animation-delay: .2s;
}
.loading__letter:nth-child(4) {
  -webkit-animation-delay: .3s;
          animation-delay: .3s; 
}
.loading__letter:nth-child(5) {
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}
.loading__letter:nth-child(6) {
  -webkit-animation-delay: .5s;
          animation-delay: .5s; 
}
.loading__letter:nth-child(7) {
  -webkit-animation-delay: .6s;
          animation-delay: .6s;
}
.loading__letter:nth-child(8) {
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}
.loading__letter:nth-child(9) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.loading__letter:nth-child(10) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0px)
  }
  40% {
    transform: translateY(-40px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px)
  }
  40% {
    transform: translateY(-40px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}


@media (max-width: 700px) {
  .loading__letter {
    font-size: 50px;
  }
}

@media (max-width: 340px) {
  .loading__letter {
    font-size: 40px;
  }
}
.navbar{
    border-bottom: 2px black solid;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.navbar-text{
    justify-content: center;
    text-align: center;
}
.body{
    margin:10px;
}

body{
    
    background: linear-gradient(270deg, #ffffff, #e4e4e4, #dadada);
    background-size: 1800% 1800%;

    -webkit-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.body-input{
    margin: auto;
    width: 80%;
    border: 3px solid #000;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.body-input-select select{
 height:40px;
 font-size: 18px;
 width:200px;
 margin:10px;
 border-radius:5px;

}

.option-default{
    color:grey;
}
.option-regular{
    color:black;
}
.prevent-click{
    pointer-events: none;
}

.body-input-button{
    text-align: center;
    padding:10px;
}

.body-input-button button{
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid black;
    cursor: pointer;
    display: inline-block;
    background: white;
    width: 100px;
}

.body-input-button button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.body-input-button button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.body-input-button button:hover span {
    padding-right: 15px;
}

.body-input-button button:hover span:after {
    opacity: 1;
    right: 0;
}

.body-output{
    margin: 10px;
}

.body-output-chart-container{
    display: flex;
    flex-wrap: wrap;
}
.chartjs-size-monitor{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}
.body-output-chart{
    width: 425px;
    border: 2px solid black;
    padding: 10px;
    margin: 10px auto;
}
.body-output-chart h2,.body-output-chart h5{
    margin:0px auto;
}
.body-output-chart h5{
    margin-bottom:10px;
}


@media screen and (max-width:600px){
    .body-output-chart{
        width: calc(100% - 20px);
        height: 270px;
    }
    .body-output-chart h2{
        font-size: 18px;
    }
}
