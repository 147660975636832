.navbar{
    border-bottom: 2px black solid;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.navbar-text{
    justify-content: center;
    text-align: center;
}